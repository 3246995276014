<template>
  <div>
    <gl-map
      ref="mapContainer"
      :init-map-options="options"
      :init-map-position="defaultMapPosition"
    />
  </div>
</template>

<script>
import GlMap from '@anagraph/gl-map'
import {
  shallowRef,
  onMounted,
  toRefs,
  computed,
  ref,
  watch,
  watchEffect
} from '@vue/composition-api'
import { fillColorExpressionBuilder } from '@/commons/utils/choropleth'
import { useMapboxPopup } from '@anagraph/composable-map'
import useRadiusMapSelection from '@/commons/composable/use-radius-map-selection'
import buildHexLayer from '@/buildHexLayer'
import layersConfig from '@/layers-config'
// import { buildVectorLayerConfig } from './layerHelper'
// import { src, style } from './layer.json'

const defaultMapPosition = {
  center: { lng: -72.872, lat: 46.331 },
  zoom: 8
}

export default {
  components: {
    GlMap
  },
  props: {
    radius: {
      type: Number,
      default: 5
    },
    pointSelectionActive: {
      type: Boolean,
      default: false
    },
    activeLayer: {
      type: String,
      default: 'stats'
    }
  },
  setup(props, { root: { $store }, emit }) {
    const transformRequest = (url, resourceType) => {
      if (
        resourceType === 'Tile' &&
        url.startsWith(process.env.VUE_APP_PROTECTED_TILES_URL)
      ) {
        return {
          url: url,
          headers: { Authorization: `Bearer ${$store.state.auth.token}` }
        }
      }
    }
    const options = { transformRequest, minZoom: 1, hash: false }
    const mapContainer = shallowRef(null)
    const { radius, pointSelectionActive, activeLayer } = toRefs(props)
    const radiusSelectionActive = pointSelectionActive
    const radiusSelectionCenter = ref(defaultMapPosition.center)
    const radiusSelectionParams = computed(() => ({
      active: radiusSelectionActive.value,
      center: radiusSelectionCenter.value,
      radius: radius.value
    }))
    useRadiusMapSelection(mapContainer, radiusSelectionParams, 11)
    onMounted(() => {
      const map = mapContainer.value.map
      map.on('load', () => {
        // const { id, source, layer } = buildVectorLayerConfig(src, style)
        // map.addSource(id, source)
        // map.addLayer(layer, style.before || 'watername_ocean')
        map.addSource('census_areas', {
          type: 'vector',
          tiles: [
            `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/squarefeet.agg_census_areas_2015_2018/{z}/{x}/{y}.pbf`
          ]
        })
        layersConfig.map(l => {
          if (l.id === 'median_sale_price') {
            map.addLayer({
              id: 'median_sale_price',
              type: 'fill',
              source: 'census_areas',
              'source-layer': 'squarefeet.agg_census_areas_2015_2018',
              paint: {
                'fill-color': fillColorExpressionBuilder.continuous({
                  property: 'median_sale_price',
                  domain: [16000, 300000],
                  palette: { name: 'Peach', steps: 7 }
                }),
                'fill-opacity': 0.5
              },
              layout: {
                visibility: l.id === activeLayer.value ? 'visible' : 'none'
              }
            })
          }
        })

        map.addSource('statshex', {
          type: 'vector',
          promoteId: 'ogc_fid',
          tiles: [
            `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/squarefeet.stats_hexa_9/{z}/{x}/{y}.pbf`
          ]
        })
        layersConfig.map(l => {
          if (l.id !== 'median_sale_price') {
            map.addLayer({
              ...buildHexLayer(l),
              layout: {
                visibility: l.id === activeLayer.value ? 'visible' : 'none'
              }
            })
          }
        })
        map.addLayer({
          id: 'statshighlight',
          type: 'fill',
          source: 'statshex',
          'source-layer': 'squarefeet.stats_hexa_9',
          paint: {
            'fill-color': [
              'case',
              ['boolean', ['feature-state', 'highlight'], false],
              'green', // orange
              'rgba(246, 246, 246, 0)' // transparent
            ],
            'fill-opacity': 0.5
          },
          before: 'road_minor_case'
        })
        map.addSource('myLayer', {
          type: 'vector',
          tiles: [
            `${process.env.VUE_APP_PROTECTED_TILES_URL}/tiles/squarefeet.prop_individ_evalu/{z}/{x}/{y}.pbf`
          ]
        })
        map.addLayer({
          id: 'layer',
          type: 'circle',
          source: 'myLayer',
          'source-layer': 'squarefeet.prop_individ_evalu',
          paint: {
            'circle-radius': [
              'interpolate',
              ['exponential', 2],
              ['zoom'],
              10,
              0,
              17,
              20
            ],
            'circle-color': 'grey'
          }
          // layout: {
          //   visibility: 'none'
          // }
        })

        map.on('click', e => {
          radiusSelectionCenter.value = e.lngLat
          emit('pointSelected', { coordinates: e.lngLat })
        })
        map.moveLayer('median_sale_price', 'layer')
        emit('loaded', true)
      })
      watchEffect(() => {
        // on utilise un watcheffect pour faire en sorte qua chaque fois que le layer avec id vaut median_sale_price
        // on enleve la fonctonnalite de voir les infos dans le panel de droite pour les hexa
        let hoveredStateId = null
        const map = mapContainer.value.map
        map.on('click', 'statshighlight', function(e) {
          const hexinfo = e.features[0].properties
          if (activeLayer.value !== 'median_sale_price') {
            emit('hexaSelected', { hexa: hexinfo })
            if (e.features.length > 0) {
              // we integrate the hover over the polygon in this if
              if (hoveredStateId) {
                map.setFeatureState(
                  {
                    source: 'statshex',
                    sourceLayer: 'squarefeet.stats_hexa_9',
                    id: hoveredStateId
                  },
                  { highlight: false }
                )
              }
              hoveredStateId = e.features[0].id
              map.setFeatureState(
                {
                  source: 'statshex',
                  sourceLayer: 'squarefeet.stats_hexa_9',
                  id: hoveredStateId
                },
                { highlight: true }
              )
            } else {
              console.log('nothing')
            }
          } else if (activeLayer.value === 'median_sale_price') {
            // on efface le dernier polygone surligne pour les hexagnes si on est sur le layer avec id 'median_sale_price'
            map.setFeatureState(
              {
                source: 'statshex',
                sourceLayer: 'squarefeet.stats_hexa_9',
                id: hoveredStateId
              },
              { highlight: false }
            )
          }
        })
      })
    })
    watch(activeLayer, newActiveLayer => {
      const map = mapContainer.value.map
      layersConfig.map(l => {
        map.setLayoutProperty(l.id, 'visibility', 'none')
      })
      map.setLayoutProperty(newActiveLayer, 'visibility', 'visible')
    })
    useMapboxPopup(mapContainer, 'layer')
    useMapboxPopup(mapContainer, 'median_sale_price')
    return { mapContainer, options, defaultMapPosition }
  }
}
</script>

<style lang="scss" scoped></style>
