<template>
  <app-panel v-if="ready">
    <template v-slot:header>
      <h2>Squarefeet</h2>
    </template>
    <template v-slot:body>
      <div class="app-panel-content">
        <layer-selector
          :selected.sync="layerSelectedValue"
          :items="layersConfig.map(l => l.id)"
        />
        <div style="cursor: pointer; text-decoration: underline">
          <h4
            @click="$emit('update:pointSelectionActive', !pointSelectionActive)"
          >
            {{ pointSelectionActive ? 'désactiver' : 'activer' }} l'outil de
            sélection
          </h4>
        </div>
        <slider-control
          v-if="pointSelectionActive"
          v-model="sliderValue"
          :labels="{ min: '500m', max: '10km' }"
          :min="0.5"
          :max="10"
          :step="0.1"
          :value-display="val => `${val} km`"
        />
        <div>
          <chloropleth-legend />
        </div>
      </div>
    </template>
    <template v-slot:footer> une réalisation Anagraph</template>
  </app-panel>
  <div v-else>chargement en cours ...</div>
</template>

<script>
import { ref, toRefs, watch } from '@vue/composition-api'
import AppPanel from '@anagraph/panel/app-panel'
import SliderControl from '@anagraph/slider-control'
import ChloroplethLegend from '../commons/components/chloropleth-legend'
import LayerSelector from './layer-selector.vue'
import layersConfig from '@/layers-config'
export default {
  components: {
    AppPanel,
    SliderControl,
    ChloroplethLegend,
    LayerSelector
  },
  props: {
    ready: {
      type: Boolean,
      default: false
    },
    pointSelectionActive: {
      type: Boolean,
      default: false
    },
    radius: {
      type: Number,
      default: 0
    },
    layerSelected: {
      type: String,
      default: 'stats'
    }
  },
  setup(props, { emit }) {
    const { radius, layerSelected } = toRefs(props)
    const sliderValue = ref(radius.value)
    const layerSelectedValue = ref(layerSelected.value)
    watch(sliderValue, () => {
      emit('update:radius', sliderValue.value)
    })
    watch(layerSelectedValue, newVal => {
      emit('update:layerSelected', newVal)
    })
    return { sliderValue, layersConfig, layerSelectedValue }
  }
}
</script>

<style lang="scss" scoped>
.app-panel-content {
  height: 50vh;
}
</style>
