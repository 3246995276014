import { scaleQuantize, scaleLinear } from 'd3-scale'
import colors from 'cartocolor'
export function getColors(palette) {
  return Array.isArray(palette) ? palette : colors[palette.name][palette.steps]
}
export function buildDomainSteps(palette, domain) {
  const colors = getColors(palette)
  const scale = scaleQuantize()
    .domain(domain)
    .range(colors)
  const entries = colors.map(c => [scale.invertExtent(c)[0], c])
  return entries
}

export function buildCustomSteps(palette, scale) {
  const colors = getColors(palette)
  const entries = colors.map((c, index) => [scale[index], c])
  return entries
}

export function buildContinousLegendSteps(domain, nbSteps) {
  const scale = scaleLinear()
    .domain(domain)
    .range([0, nbSteps])
  const entries = [...Array(nbSteps).keys()].map(c => scale.invert(c))
  return [...entries.slice(1, entries.length), domain[1]]
}

export function buildDomainLinearFillColor({ property, domain, palette }) {
  return [
    'interpolate',
    ['linear'],
    ['to-number', ['get', property], 0],
    ...buildDomainSteps(palette, domain).flat()
  ]
}

function buildDomainStepsFillColor({ property, domain, palette }) {
  return [
    'step',
    ['to-number', ['get', property], 0],
    '#ffffff',
    ...buildDomainSteps(palette, domain).flat()
  ]
}

function buildCustomStepsFillColor({ property, palette, scale }) {
  return [
    'step',
    ['to-number', ['get', property], 0],
    '#ffffff',
    ...buildCustomSteps(palette, scale).flat()
  ]
}

export const fillColorExpressionBuilder = {
  discrete: buildDomainStepsFillColor,
  continuous: buildDomainLinearFillColor,
  customDiscrete: buildCustomStepsFillColor
}
