export default ({ id, property, scale }) => ({
  id,
  type: 'fill',
  source: 'statshex',
  'source-layer': 'squarefeet.stats_hexa_9',
  paint: {
    'fill-color': ['step', ['to-number', ['get', property], 0], ...scale],
    'fill-opacity': 0.5
  },
  before: 'road_minor_case'
})
