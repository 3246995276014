<template>
  <div class="layer-selector">
    <div v-for="item in items" :key="item">
      <input :id="item" v-model="picked" type="radio" :value="item" />
      <label :for="item"> {{ item }}</label>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => ['one', 'two']
    },
    selected: {
      type: String,
      default: 'one'
    }
  },
  computed: {
    picked: {
      get() {
        return this.selected
      },
      set(value) {
        this.$emit('update:selected', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layer-selector {
  text-align: left;
  padding: 0 16px;
  margin-bottom: 24px;
  div {
    padding: 4px 0;
  }
}
</style>
