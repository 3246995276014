<template>
  <div class="hexagoneinfo">
    <h3>Hexagone Sélectionné</h3>
    <div class="formulas">
      <div v-if="hexstats" class="hexstyle">
        <div class="groupeinfo">
          <label class="title">Temps médian sur le marché</label>
          <br />
          {{ hexstats.median_time_on_market }}
        </div>
        <div class="groupeinfo">
          <label class="title">Nombre de lits médian</label>
          <br />
          {{ displayNum(hexstats.median_nbr) }}
        </div>
        <div class="groupeinfo">
          <label class="title">Année médiane de construction</label>
          <br />
          {{ hexstats.median_yea }}
        </div>
        <div class="groupeinfo">
          <label class="title">Taxes municipales</label>
          <p>{{ `${displayNum(hexstats.median_mun)} $` }}</p>
        </div>
        <div class="groupeinfo">
          <label class="title">Évaluation municipale</label>
          <br />
          {{ `${displayNum(hexstats.median_m_1)} $` }}
        </div>
        <div class="groupeinfo">
          <label class="title">Superficie médiane du terrain</label>
          <br />
          {{ displayNum(hexstats.median_lot) }}
        </div>
        <div v-if="hexstats.sale_price_per_sqfeet" class="groupeinfo">
          <label class="title">Prix de vente au pc</label>
          <br />
          {{ `${displayNum(hexstats.sale_price_per_sqfeet)} $` }}
        </div>
        <div v-if="hexstats.original_price_per_sqfeet" class="groupeinfo">
          <label class="title">Prix de vente original au pc</label>
          <br />
          {{ displayNum(hexstats.original_price_per_sqfeet) }}
        </div>
      </div>
      <div v-else>
        <p>Aucun hexagone selectionné</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // AnagraphCategories
  },
  props: {
    hexstats: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    function displayNum(val, lang = 'fr') {
      return new Intl.NumberFormat(`${lang}-CA`, {
        maximumFractionDigits: 2
      }).format(val)
    }
    return {
      displayNum
    }
  }
}
</script>

<style>
.hexagoneinfo {
  overflow-y: scroll;
  overflow-x: hidden;
}
.hexstyle {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}
.title {
  font-size: 14px;
  line-height: 22px;
  color: #3d5bf3;
}
.groupeinfo {
  flex: 1 0 28%;
  margin: 5px;
  height: 100px;
  font-size: 20px;
  line-height: 22px;
  color: #444444;
}
</style>
