<template>
  <div class="tool">
    <h3>Démographie</h3>
    <div class="formulas">
      <div class="formula">
        <label class="formula-label" for="formula-population">Population</label>
        <div id="formula-population" class="formula-value">
          {{ new Intl.NumberFormat('fr-CA').format(stats.population) }}
        </div>
      </div>
      <div class="formula">
        <label class="formula-label" for="formula-population"
          >Densité/km<sup>2</sup></label
        >
        <div id="formula-population" class="formula-value">
          {{ new Intl.NumberFormat('fr-CA').format(stats.densite) }}
          hab.
        </div>
      </div>
    </div>
    <!-- <div class="category-widget">
      <div class="category-widget-title">Classes d'âge</div>
      <anagraph-categories :items="ages" />
    </div>
    <div class="pois">
      <h4>Points d'intérêt</h4>
      <div v-for="item in pois" :key="item.id">
        {{ item.name }} : {{ item.value }}
      </div>
    </div> -->
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
// import AnagraphCategories from './commons/AnagraphCategories'

export default {
  components: {
    // AnagraphCategories
  },
  props: {
    stats: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.formulas {
  display: flex;
  width: 100%;
  padding-bottom: 24px;
  .formula {
    width: 50%;
    &-label {
      font-size: 14px;
      line-height: 22px;
      color: #3d5bf3;
    }
    &-value {
      font-size: 20px;
      line-height: 22px;
      color: #444444;
    }
  }
}
</style>
