import { watch } from '@vue/composition-api'
import CircleSelectionLayer from './CircleSelection'
/**
 * vue composable which display a circle selection when activated
 */
export default function(mapRef, radiusSelectionParamsRef, zoom = 10) {
  let circleSelectionLayer
  watch(radiusSelectionParamsRef, async (newVal, oldVal) => {
    if (!newVal.center) newVal.center = mapRef.value.map.getCenter()
    if (!circleSelectionLayer) {
      circleSelectionLayer = new CircleSelectionLayer(mapRef.value.map, zoom)
    }
    const { active, radius, center } = newVal
    circleSelectionLayer.set({ center, radius })
    if (oldVal.active !== newVal.active) {
      await circleSelectionLayer.toggle(active)
    }
  })
}
