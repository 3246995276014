export default [
  {
    id: 'sale_price_per_sqfeet',
    property: 'sale_price_per_sqfeet',
    scale: [
      '#ffffff',
      0,
      '#ffffff',
      5,
      '#e2eef9',
      14,
      '#cde0f2',
      24,
      '#b0d2e8',
      35,
      '#89bfdd',
      56,
      '#60a6d2',
      95,
      '#3e8ec4',
      156,
      '#2172b6',
      266,
      '#0a549e',
      459,
      '#08306b',
      30500,
      '#08306b'
    ]
  },
  {
    id: 'median_time_on_market',
    property: 'median_time_on_market',
    scale: [
      '#000004',
      15,
      '#000004',
      172,
      '#2d115f',
      300,
      '#721f81',
      419,
      '#b63679',
      558,
      '#f1605d',
      741,
      '#feaf77',
      1037,
      '#fcfdbf',
      1732,
      '#fcfdbf'
    ]
  },
  {
    id: 'median_sale_price',
    property: 'median_sale_price',
    scale: []
  }
]
