<template>
  <div>
    <h3>Prix de vente au pied carré</h3>
    <div v-for="(color, index) in chlorosstatshex" :key="index" class="color">
      <div
        class="color-rectangle"
        :style="{ 'background-color': color.color }"
      ></div>
      <div class="legendtext">
        <div v-if="color.min.toString() && color.max">
          {{ color.min }} à {{ color.max }} $/pc
        </div>
        <div v-if="!color.max.toString()">plus de {{ color.min }}</div>
        <div v-if="!color.min.toString()">moins de {{ color.max }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import chlorosstatshex from '../../chloros-stats-hex'
export default {
  props: {
    items: {
      type: Array,
      default: () => [
        { min: 0, max: 1000, color: 'blue' },
        { min: 1000, max: 2000, color: 'chartreuse' },
        { min: 2000, max: 3000, color: 'tomato' },
        { min: 3000, color: 'hotpink' }
      ]
    }
  },
  data() {
    return {
      chlorosstatshex
    }
  }
}
</script>

<style lang="scss" scoped>
.color {
  display: flex;
  align-items: center;
  &-rectangle {
    width: 16px;
    height: 16px;
    border: solid lightgrey 1px;
    border-radius: 5px;
    margin: 6px 8px;
  }
}
</style>
