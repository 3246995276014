import axios from 'axios'

const buildHeaders = token => ({
  Authorization: 'Bearer ' + token,
  'content-type': 'application/json'
})
export function buildFetch(baseUrl, token) {
  return {
    fetch: async (endpoint, request) => {
      const config = token ? { headers: buildHeaders(token) } : undefined
      const response = await axios.post(
        `${baseUrl}/${endpoint}`,
        request,
        config
      )
      return response.data
    }
  }
}
export const buildRequests = (params, sqlFunctions) =>
  sqlFunctions.map(sqlFunction => ({
    sqlFunction,
    params
  }))

export const get = async (fetch, sqlFunctions, params) => {
  const requests = buildRequests(params, sqlFunctions)
  const result = await fetch('pointinsights', { requests })
  console.log({ result })
  return result.data.map(({ sqlFunction, result }) => ({
    id: sqlFunction,
    value: result?.value || result
  }))
}
