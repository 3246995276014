<template>
  <div class="home">
    <left-panel-layout :is-left-panel-open="mapLoaded" class="app-layout">
      <template v-slot:left-panel>
        <left-panel
          :ready="mapLoaded"
          :radius.sync="radius"
          :point-selection-active.sync="pointSelectionActive"
          :layer-selected.sync="layerSelected"
        />
      </template>
      <template v-slot:map>
        <Map
          :radius="radius"
          :point-selection-active="pointSelectionActive"
          :active-layer="layerSelected"
          @loaded="mapLoaded = true"
          @pointSelected="pointSelected"
          @hexaSelected="hexaSelected"
        />
      </template>
    </left-panel-layout>
    <panel
      v-if="hexaSelected || (pointSelectionActive && demographicStats)"
      class="home__right-panel"
    >
      <stats-panel v-if="pointSelectionActive" :stats="demographicStats" />
      <hexinfo-panel v-if="hexaSelected" :hexstats="hexagoneinfo" />
    </panel>
  </div>
</template>

<script>
import LeftPanelLayout from '@anagraph/left-panel-layout'
import LeftPanel from '@/components/left-panel'
import Panel from '@anagraph/panel'
import Map from '@/components/map'
import { buildFetch, get as steveGet } from '@/commons/steve-client'
import StatsPanel from '@/components/stats-panel.vue'
import HexinfoPanel from '../components/hexinfo-panel.vue'
export default {
  name: 'Home',
  components: {
    LeftPanel,
    LeftPanelLayout,
    Panel,
    Map,
    StatsPanel,
    HexinfoPanel
  },
  data() {
    return {
      mapLoaded: false,
      radius: 5,
      pointSelectionActive: false,
      demographicStats: null,
      statsCoordinates: null,
      hexagoneinfo: null,
      layerSelected: 'sale_price_per_sqfeet'
    }
  },
  watch: {
    statsCoordinates() {
      this.updateStats()
    },
    radius() {
      this.updateStats()
    }
  },
  methods: {
    pointSelected({ coordinates }) {
      this.statsCoordinates = coordinates
    },
    hexaSelected({ hexa }) {
      this.hexagoneinfo = hexa
    },
    async updateStats() {
      await this.getDemoStats(this.radius, this.statsCoordinates)
    },
    async getDemoStats(radius, coordinates) {
      const fetcher = buildFetch(
        `${process.env.VUE_APP_BACKEND_URL}`,
        this.$store.state.auth.token
      )
      const sqlFunctions = [
        'age_repartition_2016',
        'densite_de_pop_2016',
        'poi_osm',
        'population_2016'
      ]

      const { lat: latitude, lng: longitude } = coordinates
      const params = { radius: radius * 1000, latitude, longitude }
      const stats = await steveGet(fetcher.fetch, sqlFunctions, params)
      this.demographicStats = {
        densite: stats.find(s => s.id === 'densite_de_pop_2016').value
          .hab_par_km2,
        ageRepartition: stats.find(s => s.id === 'age_repartition_2016').value
          .age_repartition_2016,
        pois: stats.find(s => s.id === 'poi_osm').value.poi_osm,
        population: stats.find(s => s.id === 'population_2016').value
          .population_2016
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  &__right-panel {
    position: absolute;
    width: 400px;
    z-index: 500;
    top: 50px;
    right: 50px;
  }
}
</style>
